<template>
  <div class="page">
    <main>
      <manage-tab />

      <b-field grouped>
        <b-field>
          <b-select v-model="pollId" placeholder="選択してください">
            <option v-for="option in polls" :value="option.id" :key="option.id">{{ option.title }}</option>
          </b-select>
        </b-field>
        <b-field>
          <b-datepicker v-model="startAt" />
        </b-field>
      </b-field>

      <div class="notification" v-if="votes">
        <p class="mb-3"><b>{{ votes.length }}</b> レコード (<b>{{ lastVotes.length }}</b> done)</p>

        <b-button type="is-dark" @click="buildCsvData">CSVデータを生成</b-button>

        <download-csv :data="csvData" :labels="labels" :name="`SHIFT_AWARD_${pollId}_votes_${$date().format('YYYYMMDDHHmm')}.csv`" v-if="csvData">
          <b-button icon-left="download" type="is-primary">ダウンロード</b-button>
        </download-csv>
      </div>
    </main>
  </div>
</template>


<script>
import { db } from '@/main'
import ManageTab from '@/components/manage/tab'

export default {
  components: { ManageTab },
  data() {
    return {
      polls: [],
      pollId: null,
      poll: {},
      labels: {
        id: "投票ID",
        award: "アワード",
        voterId: "ユーザー:UID",
        voterEmail: "ユーザー:メールアドレス",
        voterEmployeeCode: "ユーザー:社員番号",
        voterFullName: "ユーザー:氏名",
        voterDepartment: "ユーザー:部門",
        candidateId: "投票:UID",
        candidateEmail: "投票:メールアドレス",
        candidateEmployeeCode: "投票:社員番号",
        candidateFullName: "投票:氏名",
        candidateDepartment: "投票:部門",
        comment: "投票:コメント",
        createdAt: "投票日時"
      },
      awardList: { newcomer: "新人賞", manager: "マネージャー賞", shifter: "SHIFTER賞", unsung: "縁の下の力持ち賞", meister: "マイスター賞", other: "他部門賞"},
      votes: null,
      lastVotes: null,
      startAt: new Date(new Date().setHours(0, 0, 0, 0)),
      isProcessing: false,
      csvData: null
    }
  },
  firestore() {
    return {
      polls: db.collection("polls"),
    }
  },
  methods: {
    buildCsvData() {
      this.csvData = null

      if (this.votes) {
        this.csvData = this.votes.map((vote) => {
          const data = {
            id: vote.id,
            award: this.awardList[vote.award],
            voterId: vote.voterId,
            voterEmail: null,
            voterEmployeeCode: null,
            voterFullName: null,
            voterDepartment: null
            // voterEmail: vote.voterRef.email,
            // voterEmployeeCode: vote.voterRef.employeeCode,
            // voterFullName: vote.voterRef.fullName,
            // voterDepartment: vote.voterRef.department
          }
          
          if (vote.candidateId) {
            data.candidateId = vote.candidateId
            data.candidateEmail = null
            data.candidateEmployeeCode = null
            data.candidateFullName = null
            data.candidateDepartment = null
            // data.candidateEmail = vote.candidateRef.email
            // data.candidateEmployeeCode = vote.candidateRef.employeeCode
            // data.candidateFullName = vote.candidateRef.fullName
            data.comment = vote.comment
          }

          data.createdAt = this.$date(vote.createdAt.toDate()).format("YYYY/MM/DD hh:mm")

          return data
        })
      }
    },
    getDate() {
      const pollRef = this.$firestoreRefs.polls.doc(this.pollId)
      const lastAwardName = Object.keys(this.awardList)[Object.keys(this.awardList).length - 1]

      this.$bind("poll", pollRef)
      this.$bind("votes", pollRef.collection("votes").orderBy("createdAt").startAt(this.startAt))
      this.$bind("lastVotes", pollRef.collection("votes").where("award", "==", lastAwardName).orderBy("createdAt").startAt(this.startAt))
    }
  },
  watch: {
    pollId() {
      this.getDate()
    },
    startAt() {
      this.getDate()
    }
  }
}
</script>


<style lang="scss" scoped>
main {
  height: 100%;
  overflow-y: scroll;
  padding: 1.5rem 2.5rem;
}
</style>
